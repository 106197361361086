import { ROUTERS } from '@/constant';

import { showToastSuccess } from './Toast.util';

import { type ClassValue, clsx } from 'clsx';
import moment from 'moment';
import * as R from 'ramda';
import { isArray, isNilOrEmpty, isString } from 'ramda-adjunct';
import { twMerge } from 'tailwind-merge';

export enum KEY_CODE {
	ENTER = 13,
	ARROW_UP = 38,
	ARROW_DOWN = 40,
	BACK_SPACE = 8,
	DIGIT_0 = 48,
	TAB = 9,
	ESC = 27,
}

export type HandlerType<S extends { [key: string]: any }> = {
	[Property in keyof S]: ReturnType<S[Property]>;
};

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const insertIf = (condition: boolean, ...elements: any[]) => {
	return condition ? [...elements] : [];
};

export const insertObjectIf = (
	condition: boolean | any,
	elements1: any,
	elements2?: any
) => {
	return condition ? elements1 : elements2 ?? {};
};
export const insertObjectIfV2 = <T1 extends {}>(
	condition: boolean | any,
	elements1: T1
): Partial<T1> => {
	return condition ? elements1 : ({} as T1);
};

export const insertObjectIfElse = <T1, T2>(
	condition: boolean,
	elements1: T1,
	elements2: T2
): Partial<T1 | T2> => {
	return condition ? elements1 : elements2;
};

export const getDiffDays = (startDate: string, endDate: string) => {
	return moment(endDate).diff(moment(startDate), 'days');
};

export const replaceKeys = (
	text: string,
	keys: { param: string; value: number | string }[]
) => {
	let finalText = text;

	keys.map((key: { param: string; value: number | string }) => {
		finalText = finalText.replace(key.param, String(key.value));
		finalText = finalText.replace(key.param, String(key.value));
	});
	return finalText;
};

export const removeNullObj = (obj: any) => {
	return R.reject(R.equals(null))(obj);
};

export const jsonParse = (data: any): any => {
	try {
		return typeof data === 'object' ? data : JSON.parse(data || '{}') || {}; // JSON.parse maybe crash app
	} catch {
		return {};
	}
};

export const mapV2 = <T, S>(
	input: T[] | undefined,
	callback: (value: T, index: number, array: T[]) => S
): S[] => {
	if (!isArray(input)) return [];
	return input.map(callback);
};

export const arrayToJson = <T>(
	array: any[] = [],
	key: string = 'id'
): {
	[key: string]: T;
} => {
	try {
		if (isNilOrEmpty(array)) return {};

		const result: any = {};
		for (const item of array) {
			result[item[key]] = item;
		}

		return result;
	} catch {
		return {};
	}
};

export const copyText = (text: string, titleSuccess?: string) => {
	if (!text) return;
	try {
		navigator.clipboard.writeText(text);
		showToastSuccess(titleSuccess || 'Copied');
	} catch (error) {
		alert(error);
	}
};

export const capitalizeFirstLetter = (type: string) => {
	return type?.charAt(0)?.toUpperCase() + type?.slice(1);
};

export const onEnterKeyCondition = (
	evt: React.KeyboardEvent<HTMLElement>,
	onEnter?: () => void
) => {
	if (
		!!onEnter &&
		(evt?.keyCode === KEY_CODE.ENTER || evt?.which === KEY_CODE.ENTER) &&
		!evt?.shiftKey
	) {
		evt?.preventDefault();
		onEnter();
	}
};

export const validURL = (str: string) => {
	const pattern = new RegExp(
		'^(https?:\\/\\/)' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[^\\s\t\n]*)*(\\?[^\\s\t\n]*)?(\\#[^\\s\t\n]*)?$',
		'i'
	);

	return !!pattern.test(str);
};

export const checkMatchRouter = (
	pathname: string,
	route: string | string[]
) => {
	if (isArray(route)) {
		const shouldActive = route.find((item) => {
			return (
				(R.equals(pathname, item) && item !== ROUTERS.HOME) ||
				(item === ROUTERS.HOME && pathname === item)
			);
		});
		return !!shouldActive;
	}

	return (
		(R.equals(pathname, route) && route !== ROUTERS.HOME) ||
		(route === ROUTERS.HOME && pathname === route)
	);
};

export const generateID = (stringLength = 20) => {
	let randomStr = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
	for (let index = 0; index < stringLength; index++) {
		randomStr += characters.charAt(
			Math.floor(Math.random() * characters.length)
		);
	}
	return randomStr;
};

export const changeToSlug = (title?: string) => {
	if (!title || !isString(title)) return '';
	let slug;
	slug = title.trim().toLowerCase();
	// Đổi ký tự có dấu thành không dấu
	slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
	slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
	slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
	slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
	slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
	slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
	slug = slug.replace(/đ/gi, 'd');
	// Xóa các ký tự đặt biệt
	slug = slug.replace(
		// eslint-disable-next-line no-useless-escape
		/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi,
		''
	);
	slug = slug.replace(/ /gi, '-');
	// Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
	// Phòng trường hợp người nhập vào quá nhiều ký tự trắng
	slug = slug.replace(/\-\-\-\-\-/gi, '-');
	slug = slug.replace(/\-\-\-\-/gi, '-');
	slug = slug.replace(/\-\-\-/gi, '-');
	slug = slug.replace(/\-\-/gi, '-');
	// Xóa các ký tự gạch ngang ở đầu và cuối
	slug = `@${slug}@`;
	slug = slug.replace(/\@\-|\-\@|\@/gi, '');
	return slug;
};

export const find = <T>(
	input: T[],
	compareFunc: (element: T) => boolean
): T | undefined => {
	if (!isArray(input)) return;

	return R.find(compareFunc, input);
};

export const formatNumber = (number?: number) => {
	if (!number) return 0;
	return number.toLocaleString('en-US').replace(/,/g, '.');
};
