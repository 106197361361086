import { IFeedback } from '@/models';
import { defaultApiSauceConfig } from '@/services/api';

import apisauce from 'apisauce';

const baseURL = process.env.NEXT_PUBLIC_API_URL_V2;

const createNewApi = () => {
	const api = apisauce.create({
		...defaultApiSauceConfig(),
		baseURL,
	});

	const getNews = async (query: string) => api.get<any>(`/news?${query}`);
	const getNewBySlug = async (slug: string) =>
		api.get<any>(`/news-by-slug/${slug}`);

	const createFeedback = async (data: IFeedback) =>
		api.post(`/feedbacks`, { data });
	const getFeedbacks = async (query: string) =>
		api.get<any>(`/feedbacks?${query}`);

	return {
		api,
		getNews,
		getNewBySlug,
		getFeedbacks,
		createFeedback,
	};
};

export const NewApi = createNewApi();
