import { ISettingResponse } from '@/services/v2';
import { IResult, ResourceTypeEnum } from '@/store/LuckyDraw/LuckyDraw.type';
import { mapV2 } from '@/utils';

import { omit } from 'ramda';

export const formatSetting = (input: ISettingResponse) => {
	return {
		...omit(['typeDraw', 'prizes'], input),
		drawType: input?.typeDraw,
		resourceType: input?.resourceType ?? ResourceTypeEnum.RANGE_NUM,
		prizeList: mapV2(input?.prizes, (item) => ({
			id: item?.id,
			name: item?.name,
			resultCount: item?.resultCount,
			results: item?.results as unknown as IResult[],
		})),
		participants: mapV2(input?.participants, (item, index) => ({
			no: (index + 1)?.toString(),
			name: item?.name,
			code: item?.code,
			company: item?.company,
			position: item?.position,
			gender: item?.gender,
		})),
	};
};
