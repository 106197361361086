import qs from 'qs';

export const getQueryString = (
	obj?: any,
	options?: qs.IStringifyOptions<qs.BooleanOptional>
) => {
	return qs.stringify(
		{
			locale: 'vi',
			populate: '*',
			...obj,
		},
		{
			encodeValuesOnly: true, // This will ensure a clean query string
			...options,
		}
	);
};
