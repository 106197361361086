import { THEME_TYPE } from '@/constant/Theme.constant';
import { getThemesPayload, ITheme } from '@/models/theme.type';
import { isSuccess } from '@/services';
import { ThemeApi } from '@/services/theme.api';
import { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { ThemeActions } from './Theme.redux';

import { ApiResponse } from 'apisauce';
import { put, select } from 'redux-saga/effects';

export function* getThemes(action: PayloadAction<getThemesPayload>): any {
	try {
		const { queries, callback } = action.payload;
		const res: ApiResponse<{ data: ITheme[] }> = yield ThemeApi.getThemes({
			sort: ['priority:DESC'],
			populate: ['bg_image'],
			...queries,
		});
		if (isSuccess(res)) {
			yield put(ThemeActions.getThemesSuccess(res?.data?.data));
			callback?.();
			// set default theme
			const themeState = yield select((state: RootState) => state.theme);
			if (!themeState?.id) {
				const defaultTheme = themeState?.themes?.find(
					(theme: ITheme) =>
						theme.isDefault && theme.type === THEME_TYPE.THEME
				);
				yield put(ThemeActions.updateTheme(defaultTheme));
			}
			if (!themeState?.currentLayout?.id) {
				const defaultLayout = themeState?.themes?.find(
					(theme: ITheme) =>
						theme.isDefault &&
						theme.type === THEME_TYPE.DISPLAY_MODE
				);
				yield put(ThemeActions.updateLayout(defaultLayout || {}));
			}
			if (!themeState?.currentDial?.id) {
				const defaultDial = themeState?.themes?.find(
					(theme: ITheme) =>
						theme.isDefault && theme.type === THEME_TYPE.DRAW_STYLE
				);
				yield put(ThemeActions.updateDial(defaultDial || {}));
			}
		} else {
			yield put(ThemeActions.onFailure());
		}
	} catch (e) {
		yield put(ThemeActions.onFailure());
	}
}
