import { IEventJsonV2, IEventResponse } from '@/services/v2';
import { IEventItem } from '@/store/LuckyDraw';

import { isNilOrEmpty } from 'ramda-adjunct';

export const formatEventDetail = (input: IEventResponse) => {
	return input;
};

export const formatEventList = (input: IEventResponse[]) => {
	const eventList = [] as IEventItem[];
	let eventJson = {} as IEventJsonV2;

	if (isNilOrEmpty(input)) {
		return {
			eventList,
			eventJson,
		};
	}

	for (let i = 0; i < input.length; i++) {
		const element = input[i];
		eventList.push({ id: element.id });
		eventJson = {
			...eventJson,
			[element.id]: formatEventDetail(element),
		};
	}

	return {
		eventList,
		eventJson,
	};
};
