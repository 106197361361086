import { API_URL_V2, CacheOptionEnum, HttpMethodEnum } from '@/constant';
import { insertObjectIf } from '@/utils/Common.util';

export const getPersistedAuthToken = (): string | null => {
	const persistedState = localStorage.getItem('persist:authPersist');
	if (persistedState) {
		try {
			const state = JSON.parse(persistedState);
			return state.jwt ? JSON.parse(state.jwt) : null;
		} catch (error) {
			console.error('Failed to parse persisted auth state:', error);
			return null;
		}
	}
	return null;
};

const createV2Api = async (
	method: HttpMethodEnum,
	path: string,
	body: any,
	headers: HeadersInit = {},
	cache: CacheOptionEnum = CacheOptionEnum.DEFAULT,
	queries?: string
) => {
	const baseUrl = API_URL_V2;
	const url = new URL(path, baseUrl);

	if (queries && [HttpMethodEnum.GET, HttpMethodEnum.HEAD].includes(method)) {
		url.search = queries;
	}

	const token = getPersistedAuthToken();

	const fetchOptions: RequestInit = {
		method,
		headers: new Headers({
			'Content-Type': 'application/json',
			...insertObjectIf(!!token, { Authorization: `Bearer ${token}` }),
			...headers,
		}),
		cache,
	};

	if (body && ![HttpMethodEnum.GET, HttpMethodEnum.HEAD].includes(method)) {
		fetchOptions.body = JSON.stringify(body);
	}

	try {
		const response = await fetch(url.href, fetchOptions);
		if (!response.ok) {
			// throw new Error(`HTTP error! Status: ${response.status}`);
		}
		return response.json();
	} catch (error) {
		console.error(`${method} v2 data error: `, error);
		throw error;
	}
};

export const getV2 = (
	path: string,
	queries?: string,
	headers?: HeadersInit,
	cache?: CacheOptionEnum
) => createV2Api(HttpMethodEnum.GET, path, undefined, headers, cache, queries);

export const postV2 = (
	path: string,
	body: any,
	headers?: HeadersInit,
	cache?: CacheOptionEnum
) => createV2Api(HttpMethodEnum.POST, path, body, headers, cache);

export const putV2 = (
	path: string,
	body: any,
	headers?: HeadersInit,
	cache?: CacheOptionEnum
) => createV2Api(HttpMethodEnum.PUT, path, body, headers, cache);

export const patchV2 = (
	path: string,
	body: any,
	headers?: HeadersInit,
	cache?: CacheOptionEnum
) => createV2Api(HttpMethodEnum.PATCH, path, body, headers, cache);

export const deleteV2 = (
	path: string,
	headers?: HeadersInit,
	cache?: CacheOptionEnum
) => createV2Api(HttpMethodEnum.DELETE, path, undefined, headers, cache);

export const isSuccessV2 = (props: any) => {
	return !props?.error;
};
