import { THEME_TYPE } from '@/constant/Theme.constant';
import { getThemesPayload, ITheme } from '@/models/theme.type';
import { RootState } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IThemeState = {
	themes?: ITheme[];
	fetching: boolean;
	dialId?: number;
	layoutId?: number;
	currentLayout?: ITheme;
	currentDial?: ITheme;
} & ITheme;

const initialState: IThemeState = {
	dialId: undefined,
	layoutId: undefined,
	themes: [],
	fetching: false,
	currentLayout: {},
	currentDial: {},
};

const themeSlice = createSlice({
	initialState,
	name: 'theme',
	reducers: {
		updateTheme: (state, action: PayloadAction<ITheme>) => {
			const {
				id,
				bg_image,
				name,
				gradient_draw_inside,
				bg_draw_inside,
				gradient_border_outside,
				bg_table,
				bg_table_prize,
				bg_btn,
				bg_modal_btn,
				bg_btn_icon,
			} = action?.payload;

			// For common
			state.id = id;
			state.name = name;
			state.bg_image = bg_image;

			// For Draw
			state.gradient_draw_inside = gradient_draw_inside;
			state.bg_draw_inside = bg_draw_inside;
			state.gradient_border_outside = gradient_border_outside;

			// For table
			state.bg_table = bg_table;
			state.bg_table_prize = bg_table_prize;

			// For modal, btn icon
			state.bg_btn = bg_btn;
			state.bg_modal_btn = bg_modal_btn;
			state.bg_btn_icon = bg_btn_icon;
		},
		updateThemeSuccess: (state) => {},
		updateThemeFailure: (state) => {},

		updateDial: (state, action: PayloadAction<ITheme>) => {
			state.currentDial = action.payload;
		},
		updateDialSuccess: (state) => {},
		updateDialFailure: (state) => {},

		updateLayout: (state, action: PayloadAction<ITheme>) => {
			state.currentLayout = action.payload;
		},
		updateLayoutSuccess: (state) => {},
		updateLayoutFailure: (state) => {},

		getThemes: (state, action: PayloadAction<getThemesPayload>) => {
			state.fetching = true;
		},
		getThemesSuccess: (
			state,
			action: PayloadAction<ITheme[] | undefined>
		) => {
			state.fetching = false;
			state.themes = action.payload || [];
		},

		onFailure: (state) => {
			state.fetching = false;
		},
	},
});

export const ThemeSelectors = {
	themeSelector: (state: RootState) => state.theme,
	selectThemeData: (state: RootState) => {
		const themeData = state?.theme?.themes
			?.filter((theme: ITheme) => theme.type === THEME_TYPE.THEME)
			?.sort(
				(a: ITheme, b: ITheme) => (b.priority || 0) - (a.priority || 0)
			);

		const layoutData = state?.theme?.themes
			?.filter((theme: ITheme) => theme.type === THEME_TYPE.DISPLAY_MODE)
			?.sort(
				(a: ITheme, b: ITheme) => (b.priority || 0) - (a.priority || 0)
			);

		const dialData = state?.theme?.themes
			?.filter((theme: ITheme) => theme.type === THEME_TYPE.DRAW_STYLE)
			?.sort(
				(a: ITheme, b: ITheme) => (b.priority || 0) - (a.priority || 0)
			);

		return { themeData, layoutData, dialData };
	},
};

export const ThemeActions = themeSlice.actions;
export const ThemeReducer = themeSlice.reducer;
