import { setApiAuthorization } from '@/services';
import { getProfile, IUser } from '@/services/v2';
import { isSuccessV2 } from '@/utils/v2';

import { AuthSelector } from '../Auth';
import { ProfileActions } from './Profile.redux';

import { isNilOrEmpty } from 'ramda-adjunct';
import { put, select } from 'redux-saga/effects';

export function* getMyProfile() {
	try {
		const jwt: string = yield select(AuthSelector.selectToken);

		if (isNilOrEmpty(jwt)) {
			yield put(ProfileActions.getProfileFailure());
			return;
		}

		setApiAuthorization(jwt);

		const profile: IUser = yield getProfile();

		if (isSuccessV2(profile)) {
			yield put(ProfileActions.getProfileSuccess(profile));
		} else {
			yield put(ProfileActions.getProfileFailure());
		}
	} catch (error) {
		yield put(ProfileActions.getProfileFailure());
	}
}
