import { ArticleType, LocaleType } from '@/models';
import { PaginationType } from '@/models/api.type';
import { NewApi } from '@/services/new.api';
import { formatListData, getQueryString, isSuccess } from '@/utils';

export const getNewArticles = async ({
	articleType = ArticleType.RECENT,
	pageSize = 4,
	page = 1,
	locale = LocaleType.VI,
	queries = {} as any,
}): Promise<{ data: any[]; pagination: PaginationType }> => {
	const defaultPagination = {
		page,
		pageSize,
		pageCount: 0,
		total: 0,
	};

	const res = await NewApi.getNews(
		getQueryString({
			filters: {
				type: {
					$eq: articleType,
				},
			},
			pagination: {
				pageSize,
				page,
			},
			...queries,
		})
	);

	return formatListData(res.data, defaultPagination);
};

export const getNewArticle = async (slug: string) => {
	const res = await NewApi.getNewBySlug(slug);

	if (isSuccess(res)) {
		return res.data || {};
	}

	return {};
};
