import { NumberStatus } from '@/app/(vi)/home/Home.logic';
import { Images } from '@/app/assets/images';
import { IDialSetting } from '@/store/LuckyDraw';

import { mapV2 } from './Common.util';

import { flatten, range } from 'ramda';

export const randomNumbersWithPadding = (
	minValue: number,
	maxValue: number,
	ignoreList: number[] = [],
	prizeNumber: number = 1
) => {
	if (prizeNumber <= 0) {
		return [];
	}

	const selectedNumbers = [];
	let i = 0;

	while (i < prizeNumber) {
		let randomNum;

		do {
			randomNum = Math.floor(
				Math.random() * (maxValue - minValue + 1) + minValue
			);
		} while (ignoreList.includes(randomNum));

		selectedNumbers.push(
			String(randomNum).padStart(maxValue.toString().length, '0')
		);

		ignoreList.push(randomNum);
		i++;
	}

	return selectedNumbers;
};

export const getRandomCodesFromParticipants = (
	participants: IDialSetting['participants'],
	codeMaxLength: number,
	ignoreList: string[] = [],
	prizeNumber: number = 1
) => {
	if (participants.length === 0 || prizeNumber <= 0) {
		return [];
	}

	const selectedParticipants = [];
	let i = 0;

	while (i < prizeNumber) {
		let randomIndex;
		let randomParticipant;

		do {
			randomIndex = Math.floor(Math.random() * participants.length);
			randomParticipant = participants[randomIndex];
		} while (ignoreList.includes(randomParticipant?.code));

		selectedParticipants.push({
			code:
				randomParticipant.code.length < codeMaxLength
					? randomParticipant.code.padStart(codeMaxLength, '0')
					: randomParticipant.code,
			participant: randomParticipant,
		});

		ignoreList.push(randomParticipant.code);
		i++;
	}

	return selectedParticipants;
};

export const getAllResult = (
	params: { prizes: IDialSetting['prizeList']; codeMaxLength: number } & (
		| {
				participants: IDialSetting['participants'];
				isRange: false;
		  }
		| {
				minNumber: number;
				maxNumber: number;
				isRange: true;
		  }
	)
) => {
	const { prizes, codeMaxLength, isRange } = params;

	const ignoreList: any[] = [];
	const results: IDialSetting['prizeList'] = [];

	for (let i = 0; i < prizes.length; i++) {
		const element = prizes[i];
		if (isRange) {
			const res = randomNumbersWithPadding(
				params?.minNumber,
				params?.maxNumber,
				ignoreList,
				element?.resultCount
			);

			results.push({
				...element,
				results: mapV2(res, (item) => ({
					code: item,
					name: '',
				})),
			});
		} else {
			const res = getRandomCodesFromParticipants(
				params?.participants,
				codeMaxLength,
				ignoreList,
				element?.resultCount
			);

			results.push({
				...element,
				results: mapV2(res, (item) => ({
					code: item?.code,
					name: item?.participant?.name,
				})),
			});
		}
	}

	return results;
};

export const getCodeMaxLength = (
	participants: IDialSetting['participants']
) => {
	return participants.reduce((max, participant) => {
		return Math.max(max, participant.code.length);
	}, 0);
};

export const getInitNumbers = ({ maxLength }: { maxLength: number }) => {
	return range(0, maxLength || 3).map((item) => 0);
};

export const handleDataAfterSpin = (params: {
	isRange: boolean;
	includeAllResult?: boolean;
	setting: IDialSetting;
	codeMaxLength: number;
}) => {
	const {
		includeAllResult = false,
		isRange,
		setting,
		codeMaxLength,
	} = params;

	let allPrizeList: IDialSetting['prizeList'] = [];
	let randomNumbers: string[] | number[] = [];
	let resultList: IDialSetting['prizeList'][0]['results'] = [];

	if (includeAllResult) {
		allPrizeList = getAllResult({
			codeMaxLength,
			isRange,
			minNumber: setting?.minNumber,
			maxNumber: setting?.maxNumber,
			prizes: setting?.prizeList,
			participants: setting?.participants,
		});
		randomNumbers = allPrizeList?.[0]?.results?.[0]?.code
			?.split('')
			?.map(Number);
	} else {
		const currentResultList = flatten(
			mapV2(setting?.prizeList, (item) => item?.results)
		);

		if (isRange) {
			randomNumbers = randomNumbersWithPadding(
				setting?.minNumber,
				setting?.maxNumber,
				mapV2(currentResultList, (item) => Number(item?.code))
			)[0]
				?.split('')
				?.map(Number);

			resultList = [
				{
					code: randomNumbers?.join(''),
					name: '',
				},
			];
		} else {
			const [{ code, participant }] = getRandomCodesFromParticipants(
				setting?.participants,
				codeMaxLength,
				mapV2(currentResultList, (item) => item?.code)
			);

			randomNumbers = code?.split('');

			resultList = [
				{
					code: randomNumbers?.join(''),
					name: participant?.name,
				},
			];
		}
	}

	return {
		randomNumbers,
		allPrizeList,
		resultList,
	};
};

export const getRestAutoSpinNumber = (params: {
	setting: IDialSetting;
	prizeIndex: number;
	isAutoDraw: boolean;
}) => {
	const { setting, prizeIndex, isAutoDraw } = params;

	if (!isAutoDraw) return 0;

	const currentResultCount =
		setting?.prizeList?.[prizeIndex]?.results?.length ?? 0;
	const resultSettingCount =
		setting?.prizeList?.[prizeIndex]?.resultCount ?? 0;
	const autoSpinSettingCount = setting?.drawCount ?? 0;

	if (currentResultCount % autoSpinSettingCount === 0) {
		return Math.min(
			resultSettingCount - currentResultCount,
			autoSpinSettingCount
		);
	}

	if (currentResultCount < autoSpinSettingCount) {
		return autoSpinSettingCount - currentResultCount;
	}

	return Math.min(
		autoSpinSettingCount - (currentResultCount % autoSpinSettingCount),
		resultSettingCount - currentResultCount
	);
};

export const getImageHome = (input: {
	prizeIndex: number;
	prizeLength: number;
}) => {
	const { prizeIndex, prizeLength } = input;
	if (prizeIndex === prizeLength - 1) {
		return Images.one;
	}
	if (prizeIndex === prizeLength - 2) {
		return Images.two;
	}

	return Images.three;
};

export const getBtnMainLabel = (input: {
	drawDone: boolean;
	status: NumberStatus;
	t: any;
}) => {
	const { drawDone, status, t } = input;
	return drawDone
		? t('home.btn_title_done')
		: status === NumberStatus.ENDING
		? t('home.btn_title_stopping')
		: status === NumberStatus.FLIPPING
		? t('home.btn_title_pause')
		: t('home.btn_title_start');
};

export const generateEventCode = (length: number = 6): string => {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let result = 'LD';
	for (let i = 0; i < length; i++) {
		result += characters.charAt(
			Math.floor(Math.random() * characters.length)
		);
	}
	return result;
};
