import { IFeedback } from '@/models';
import { isSuccess, NewApi } from '@/services';
import { FeedbackActions } from '@/store/Feedback/Feedback.redux';
import { PayloadAction } from '@reduxjs/toolkit';

import { ApiResponse } from 'apisauce';
import { put } from 'redux-saga/effects';

export function* createFeedback(action: PayloadAction<any>) {
	try {
		const { data, callback } = action.payload;
		const res: ApiResponse<IFeedback> = yield NewApi.createFeedback(data);
		if (isSuccess(res)) {
			yield put(FeedbackActions.createFeedbackSuccess);
			callback?.();
		} else {
			yield put(FeedbackActions.createFeedbackFailure);
		}
	} catch (e) {
		yield put(FeedbackActions.createFeedbackFailure);
	}
}
