import { defaultApiSauceConfig } from '@/services/api';

import { IGetThemeParams } from './api.type';

import apisauce from 'apisauce';

export const baseURL = process.env.NEXT_PUBLIC_API_URL_V2;

const createThemeApi = () => {
	const api = apisauce.create({
		...defaultApiSauceConfig(),
		baseURL,
	});

	const getThemes = async (data: IGetThemeParams) => api.get('/themes', data);

	return {
		api,
		getThemes,
	};
};

export const ThemeApi = createThemeApi();
