import {
	getQueryString,
	IAuthResponseV2,
	ILoginBody,
	IRegisterBody,
	IUser,
} from '@/services/v2';
import { getV2, postV2 } from '@/utils/v2';

export const registerV2 = async (body: IRegisterBody) => {
	const data: IAuthResponseV2 = await postV2('/auth/local/register', body);
	return data;
};

export const loginV2 = async (body: ILoginBody) => {
	const data: IAuthResponseV2 = await postV2('/auth/local', body);
	return data;
};

export const getProfile = async () => {
	const data: IUser = await getV2(`/users/me`, getQueryString());

	return data;
};
