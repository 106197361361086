import { IFeedback } from '@/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IFeedbackState = {
	fetching: true,
	feedback: null,
};

type IFeedbackState = {
	fetching: boolean;
	feedback: IFeedback | null;
};
const feedbackSlice = createSlice({
	initialState,
	name: 'feedback',
	reducers: {
		createFeedbackRequest: (state, action: PayloadAction<any>) => {
			state.fetching = true;
		},
		createFeedbackSuccess: (state) => {
			state.fetching = false;
		},
		createFeedbackFailure: (state) => {
			state.fetching = false;
		},
	},
});

export const FeedbackSelectors = {
	selectFetching: (state: RootState) => state.feedback.fetching,
};

export const FeedbackActions = feedbackSlice.actions;
export const FeedbackReducer = feedbackSlice.reducer;
