import {
	getQueryString,
	ICreateEvent,
	IEventResponse,
	IEventsResponse,
} from '@/services/v2';
import { deleteV2, getV2, postV2, putV2 } from '@/utils/v2';

export const getEvents = async (userId: string) => {
	const data: IEventsResponse = await getV2(
		`/api/events`,
		// Query to get events by userId
		getQueryString({
			filters: {
				userId: {
					documentId: { $eq: userId },
				},
			},
		})
	);

	return data;
};

export const getEvent = async (eventId: string, queries?: string) => {
	const data: IEventResponse = await getV2(`/api/events/${eventId}`, queries);
	return data;
};

export const createEvent = async (body: ICreateEvent) => {
	const data: IEventResponse = await postV2('/api/events', {
		data: body,
	});
	return data;
};

export const updateEvent = async (eventId: string, body: ICreateEvent) => {
	const data: IEventResponse = await putV2(`/api/events/${eventId}`, {
		data: body,
	});
	return data;
};

export const deleteEvent = async (eventId: string) => {
	const data: IEventResponse = await deleteV2(`/api/events/${eventId}`);
	return data;
};
