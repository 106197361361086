import { ICreateEvent, ISettingResponse } from '@/services/v2';
import { deleteV2, getV2, postV2, putV2 } from '@/utils/v2';

export const getSettingByEventId = async (
	eventId: number,
	queries?: string
) => {
	const data: ISettingResponse = await getV2(
		`/api/find-setting-by-event-id/${eventId}`,
		queries
	);
	return data;
};

export const createSetting = async (body: ICreateEvent) => {
	const data: ISettingResponse = await postV2('/api/events', {
		data: body,
	});
	return data;
};

export const updateSetting = async (eventId: string, body: ICreateEvent) => {
	const data: ISettingResponse = await putV2(`/api/events/${eventId}`, {
		data: body,
	});
	return data;
};

export const deleteSetting = async (eventId: string) => {
	const data: ISettingResponse = await deleteV2(`/api/events/${eventId}`);
	return data;
};
