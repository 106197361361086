export const ROUTERS = {
	HOME: '/',
	LOGIN: '/login',
	LICENSE: '/license',
	PAYMENT: '/payment',
	SETTING_PROFILE: '/account/profile',
	SETTING_EVENT: '/account/events',
	NEWS: '/news',
	NEWS_DETAIL: '/news/:slug',
};

export const navigation = [
	{
		label: 'menu.account_management',
		href: ROUTERS.SETTING_PROFILE,
	},
	{
		label: 'menu.event_management',
		href: ROUTERS.SETTING_EVENT,
	},
];
