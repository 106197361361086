import { GeneralActions, GeneralSelectors, ModalType } from '@/store/General';
import { insertIf } from '@/utils';

import { AuthSelector } from '../Auth';
import { LuckyDrawActions } from '../LuckyDraw';
import { ProfileActions } from '../Profile';
import { ThemeActions } from '../Theme';

import { all, put, select } from 'redux-saga/effects';

export function* startup() {
	/** START UP */
	const logged: boolean = yield select(AuthSelector.selectLogged);
	const isFirstTimeVisit: boolean = yield select(
		GeneralSelectors.selectIsFirstTimeVisit
	);

	yield all([
		put(ProfileActions.getProfileRequest()),
		put(ThemeActions.getThemes({})),
		...insertIf(!logged, put(LuckyDrawActions.setSelectedEvent(0))),
		...insertIf(
			isFirstTimeVisit,
			put(GeneralActions.openModalByType(ModalType.INSTRUCT))
		),
	]);
}
