export const API_URL_V2 = process.env.NEXT_PUBLIC_API_URL_V2;

export enum CacheOptionEnum {
	DEFAULT = 'default',
	NO_STORE = 'no-store',
	RELOAD = 'reload',
	NO_CACHE = 'no-cache',
	FORCE_CACHE = 'force-cache',
	ONLY_IF_CACHED = 'only-if-cached',
}

export enum HttpMethodEnum {
	HEAD = 'HEAD',
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE',
}
