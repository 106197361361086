export enum ArticleType {
	RECENT = 'RECENT',
	RELATED = 'RELATED',
	NEW_FEATURES = 'NEW_FEATURES',
	PROMOTIONAL_EVENT = 'PROMOTIONAL_EVENT',
	ACTUAL_EVENT = 'ACTUAL_EVENT',
}

export enum PaginationPageType {
	PAGE = 'page',
	RECENT = 'recentPage',
	RELATED = 'relatedPage',
	NEW_FEATURES = 'newFeaturesPage',
	PROMOTIONAL_EVENT = 'promotionalEventPage',
	ACTUAL_EVENT = 'actualEventPage',
	FEED_BACK = 'feedbackPage',
}
